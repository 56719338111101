import React from 'react';

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msg: '',
      authKey: '',
      submitting: false,
      response: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    fetch('https://worker-faq.walshy.dev/content')
    .then(res => res.text())
    .then(msg => {
      this.setState({ msg })
    })
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSubmit() {
    const { msg, authKey } = this.state;

    this.setState({ submitting: true });

    fetch(`https://worker-faq.walshy.dev/content`, {
      method: 'PUT',
      headers: {
        Authorization: authKey
      },
      body: msg
    })
      .then(res => res.json())
      .then(obj => {
        if (!obj.success) {
          this.setState({ submitting: false, response: { class: 'error', message: obj.error } })
        } else {
          this.setState({ submitting: false, response: { message: 'Updated!' } })
        }
      })
      .catch(error => {
        this.setState({ submitting: false, response: { class: 'error', message: error.message } })
      })
  }

  render() {
    const { msg, authKey, submitting, response } = this.state;

    return (
      <div className="container">
        <span>Update the FAQ message in the Cloudflare Worker Discord</span>
        
        <div className="input-group">
          <input
            type="text"
            className="input"
            onChange={this.onChange}
            name="authKey"
            placeholder="Auth Token"
            value={authKey}
          />

          <button
            type="submit"
            className="button"
            onClick={this.onSubmit}
            disabled={submitting}
          >
            Save
          </button>
        </div>

        {response && <div><span className={response.class}>{response.message}</span></div>}

        <textarea
          id="textblock"
          name="msg"
          autoCorrect="off"
          spellCheck="true"
          cols="40"
          rows="40"
          wrap="soft"
          onChange={this.onChange}
          value={msg}
        />
      </div>
    );
  }
}
